<template>
    <div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>

                    <div class="table-header-panel text-right">
                         <span style="
                                    margin-left:0px;
                                    font-size:14px;
                                    color:#999999;
                                    line-height:28px;
                                    float: left;
                                    margin-top: 4px;
                        ">
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            添加的人员可实现特权事件
                        </span>
                        <button class="btn btn-primary sticky-right" @click="openAddVipUser">
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            人员
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>姓名</th>
                        <th>手机号</th>
                        <th>特权事件</th>
                        <th>操作</th>
                    </tr>
                </template>

                <template v-slot:tbody>
                    <tr v-for="vipUser in vipUserList" :key="vipUser.id">
                        <td>{{vipUser.createTime}}</td>
                        <td>{{vipUser.name || `-`}}</td>
                        <td>{{vipUser.phone}}</td>
                        <td>
                             <span class="allow-click" @click="openShowVipUser(vipUser)">
                                查看
                            </span>
                        </td>
                        <td>
                            <div class="btn-group">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>

                                <ul class="dropdown-menu" style="width: 112px">
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="openEditVipUser(vipUser)"
                                        >
                                            修改特权事件
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="deleteVipUser(vipUser.id)"
                                        >
                                            删除人员
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination name="Pagination" component="Pagination"></Pagination>
        </div>
        <!--添加-->
        <CSDialog
                :dialogTitle="`添加人员`"
                :dialog-visible="addVipUserFlag"
                :dialog-width="750"
                @onClose="closeAddVipUser(1)"
                @onConfirm="checkboxChange(1)"

        >
            <template v-slot:dialog-content>
                <div class="dialog-form" @click="lesseeList = []">
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">手机号</div>
                        <div>
                            <ChooseLocation
                                    mission="deputy"
                                    style="--readonly-input-width: 307px"
                                    @changeLocation="changeLocation"
                                    :showInput="false"
                                    :showType="true"
                                    v-model="addVipUserParams.phone"
                            />
                        </div>
                    </div>

                    <div style="margin-left: 188px;margin-top: 0px;margin-bottom:0px">
                        <svg
                                class="icon"
                                aria-hidden="true"
                                style="font-size: 20px; margin-right: 10px"
                        >
                            <use xlink:href="#icon-menua-zu92"></use>
                        </svg>

                        <span style="font-size: 20px;color: #999999"
                        >只显示已注册棒棒我鸭用户。</span
                        >
                    </div>

                    <div class="dialog-form-item" style="padding-top: 20px">
                        <div class="dialog-form-item-label">特权事件</div>
                        <div class="info-item">
                            是否显示VIP标识
                            <CSRadio style="margin-left: 52px;vertical-align: 2px" v-model="addVipUserParams.showVip"
                                     :items="{0: '否', 1: '是'}"/>
                        </div>
                    </div>
                    <div class="dialog-form-item" style="padding-top: 10px">
                        <div class="dialog-form-item-label"></div>
                        <div class="info-item">
                            是否填写个人信息
                            <CSRadio style="margin-left: 40px;vertical-align: 2px"
                                     v-model="infoCheckBox" :items="{0: '否', 1: '是'}"/>
                        </div>
                    </div>


                    <div class="dialog-form-item" style="padding-top: 30px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label">姓名</div>
                        <div class="info-item">
                            <input
                                    type="text"
                                    style="
                  width: 400px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限2~5个字"
                                    v-model="addVipUserParams.name"
                                    maxlength="5"
                                    minlength="2"
                            />
                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 0px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label"></div>
                        <div class="info-item">
                            <!--                            <CSRadio   style="margin-left: 0px" v-model="addVipUserParams.matching"-->
                            <!--                                     :items="{ 1: '不用匹配姓名与身份证'}"/>-->
                            <div style="display: flex;align-items: center" >
                                <input  class='nameSlect' type="checkbox"
                                       @change="ccc" :style="[{marginLeft:'0px'}]"
                                       v-model="addVipUserParams.matching"/>不用匹配姓名与身份证<span v-if="isUpdate">(此用户已认证)</span>
                            </div>

                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 20px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label">性别</div>
                        <div class="info-item">
                            <CSRadio style="vertical-align: 2px" v-model="addVipUserParams.gender"
                                     :items="{1: '男', 2: '女'}"/>
                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 20px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label">生日</div>
                        <div class="info-item" style="height: 40px">
                            <CSSelect i-width="40px" class="wDatad" style="height: 40px">
                                <el-date-picker
                                        type="date"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        placeholder="请选择"
                                        prefix-icon="el-icon-time"
                                        :editable="true"
                                        v-model="addVipUserParams.birthday"
                                ></el-date-picker>
                            </CSSelect>
                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 20px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label" style="vertical-align: top;line-height: 28px">人脸照片</div>
                        <div class="info-item">
                            <div v-if="addVipUserParams.imageUrl !== ''" style="position: relative">
                                <img
                                        :src="addVipUserParams.imageUrl"
                                        alt=""
                                        @click="uploadAddEnterprisePhoto"
                                        style="width: 100px; height: 100px"
                                />
                                <img
                                        @click="deleteFacePhoto()"
                                        src="../../assets/icon4.png"
                                        style="width: 15px;height: 15px;position: absolute;top: -5px;right: -5px;"
                                />
                            </div>
                            <div v-else>
                                <div class="upload-photo" @click="uploadAddEnterprisePhoto">
                                    <div class="text-center">
                                        <img
                                                src="../../assets/upload.png"
                                                alt=""
                                                style="margin: 5px auto"
                                        />
                                        <p style="color: #999">上传照片</p>
                                    </div>
                                </div>
                            </div>
                            <input
                                    type="file"
                                    class="file"
                                    accept="images/*"
                                    id="uploadAddEnterprisePhotoId"
                                    hidden
                                    @change="showImage($event)"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="dialog-form-item" style="padding-top: 20px;" v-if="infoCheckBox === 1">
                            <div class="dialog-form-item-label">所属企业</div>
                            <div class="info-item" style="position: relative">
                                <input
                                        type="text"
                                        style="
                  width: 400px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                        placeholder="搜索企业名称"
                                        v-model="search"
                                        @click="searchLessee"
                                        @input="searchLessee"
                                />
                                <TreePanel style="z-index: 100; overflow: hidden" v-if="lesseeList.length>0">

                                    <div :key="item.id" v-for="item in lesseeList" @click="changeLessee(item,1)"
                                         style="cursor: pointer">
                                        {{item.companyName}}
                                    </div>
                                </TreePanel>
                            </div>

                        </div>

                    </div>


                    <div style="margin-top: 30px"></div>
                </div>
            </template>
        </CSDialog>
        <!--查看-->
        <CSDialog
                dialog-title="查看特权事件"
                dialog-width="510px"
                dialog-visible
                :dialogVisible="showVipUserFlag"
                :dialogShowConfirmBtn="false"
                dialogCancelBtnText="关闭"
                @onClose="showVipUserFlag = false"
                dialog-header-class="alert-bg"
        >
            <template v-slot:dialog-content>
                <div class="dialog-content">
                    <div class="dialog-form">
                        <div class="dialog-form-field" style="margin-bottom: 10px;width: auto"
                             v-if="showVipUserInfo.showVip == 1">
                            <div class="field-name" style="text-align: left;margin-left: 20px">
                                <span class="dot">
                                </span>
                                <span>
                                    显示VIP标识
                                </span>
                            </div>
                        </div>

                        <div class="dialog-form-field" style="margin-bottom: 10px"
                             v-if="showVipUserInfo.name || showVipUserInfo.gender == 2 || showVipUserInfo.gender==1 || showVipUserInfo.birthday || showVipUserInfo.faceImage || showVipUserInfo.lesseeName">
                            <div class="field-name" style="text-align: left;margin-left: 20px"><span class="dot"></span>个人信息
                            </div>
                        </div>

                        <div style="width: 100%;height: 1px;background: rgba(240,240,240,1);margin: 30px 0">

                        </div>

                        <div class="privilegeClass" v-if="showVipUserInfo.name">
                            <div class="privilegelab">姓名</div>
                            <div>
                                {{ showVipUserInfo.name }}<span
                                    v-if="showVipUserInfo.matching == 1 ">|不用匹配姓名与身份证</span>
                            </div>
                        </div>

                        <div class="privilegeClass"
                             v-if="showVipUserInfo.gender == 2 || showVipUserInfo.gender==1">
                            <div class="privilegelab">性别</div>
                            <div>
                                {{ showVipUserInfo.gender ==2 ?'女':'男' }}
                            </div>
                        </div>

                        <div class="privilegeClass" v-if="showVipUserInfo.birthday ">
                            <div class="privilegelab">生日</div>
                            <div>
                                {{ showVipUserInfo.birthday }}
                            </div>
                        </div>

                        <div class="privilegeClass" v-if="showVipUserInfo.faceImage">
                            <div class="privilegelab">人脸照片</div>
                            <div>
                                <span

                                >
                                  <img
                                          :src="showVipUserInfo.faceImage"
                                          alt=""
                                          style="width: 200px"
                                  />
                                </span>
                            </div>
                        </div>

                        <div class="privilegeClass" style="margin-bottom: 0" v-if="showVipUserInfo.lesseeName">
                            <div class="privilegelab">所属企业</div>
                            <div>
                                {{ showVipUserInfo.lesseeName }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--修改-->
        <CSDialog
                :dialogTitle="`修改特权事件`"
                :dialog-visible="openEditVipUserFlag"
                :dialog-width="750"
                @onClose="closeAddVipUser(2)"
                @onConfirm="checkboxChange(2)"

        >
            <template v-slot:dialog-content>
                <div class="dialog-form" @click="lesseeList = []">


                    <div class="dialog-form-item" style="padding-top: 20px">
                        <div class="dialog-form-item-label">特权事件</div>
                        <div class="info-item">
                            是否显示VIP标识
                            <CSRadio style="margin-left: 52px;vertical-align: 2px" v-model="editVipUser.showVip"
                                     :items="{0: '否', 1: '是'}"/>
                        </div>
                    </div>
                    <div class="dialog-form-item" style="padding-top: 10px">
                        <div class="dialog-form-item-label"></div>
                        <div class="info-item">
                            是否填写个人信息
                            <CSRadio style="margin-left: 40px;vertical-align: 2px" v-model="infoCheckBox"
                                     :items="{0: '否', 1: '是'}"/>
                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 30px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label">姓名</div>
                        <div class="info-item">
                            <input
                                    type="text"
                                    style="
                  width: 400px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限2~5个字"
                                    v-model="editVipUser.name"
                                    maxlength="5"
                                    minlength="2"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item" style="padding-top: 0px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label"></div>
                        <div class="info-item">
                            <!--                            <CSRadio style="margin-left: 0px" v-model="editVipUser.matching"-->
                            <!--                                     :items="{ 1: '不用匹配姓名与身份证'}"/>-->

                            <div style="display: flex;align-items: center" >
                                <input class="nameSlect" type="checkbox" @change="setMatch" style="margin-left: 0px"
                                       v-model="editVipUser.matching"/>不用匹配姓名与身份证<span v-if="isUpdate">(此用户已认证)</span>
                            </div>
                        </div>
                    </div>
                    <div class="dialog-form-item" style="padding-top: 20px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label">性别</div>
                        <div class="info-item">
                            <CSRadio v-model="editVipUser.gender" style="vertical-align: 2px"
                                     :items="{1: '男', 2: '女'}"/>
                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 20px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label">生日</div>
                        <div class="info-item">
                            <CSSelect i-width="40px" class="wDatad" style="height: 40px">
                                <el-date-picker
                                        type="date"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        placeholder="请选择"
                                        prefix-icon="el-icon-time"
                                        :editable="true"
                                        v-model="editVipUser.birthday"
                                ></el-date-picker>
                            </CSSelect>
                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 20px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label" style="vertical-align: top;line-height: 28px">人脸照片</div>
                        <div class="info-item">
                            <div v-if="editVipUser.faceImage !== ''" style="position: relative">
                                <img
                                        :src="editVipUser.faceImage"
                                        alt=""
                                        @click="uploadAddEnterprisePhoto1"
                                        style="width: 100px; height: 100px"
                                />
                                <img
                                        @click="deleteFacePhoto1()"
                                        src="../../assets/icon4.png"
                                        style="width: 15px;height: 15px;position: absolute;top: -5px;right: -5px;"
                                />
                            </div>
                            <div v-else>
                                <div class="upload-photo" @click="uploadAddEnterprisePhoto1">
                                    <div class="text-center">
                                        <img
                                                src="../../assets/upload.png"
                                                alt=""
                                                style="margin: 5px auto"
                                        />
                                        <p style="color: #999">上传照片</p>
                                    </div>
                                </div>
                            </div>
                            <input
                                    type="file"
                                    class="file"
                                    accept="images/*"
                                    id="uploadAddEnterprisePhotoId1"
                                    hidden
                                    @change="showImage1($event)"
                            />
                        </div>
                    </div>

                    <div class="dialog-form-item" style="padding-top: 20px" v-if="infoCheckBox === 1">
                        <div class="dialog-form-item-label">所属企业</div>
                        <div class="info-item" style="position: relative">
                            <input
                                    type="text"
                                    style="
                  width: 400px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="搜索企业名称"
                                    v-model="search"
                                    @click="searchLessee"
                                    @input="searchLessee"
                            />
                            <TreePanel style="z-index: 100; overflow: hidden" v-if="lesseeList.length>0">

                                <div :key="item.id" v-for="item in lesseeList" @click="changeLessee(item,1)"
                                     style="cursor: pointer">
                                    {{item.companyName}}
                                </div>
                            </TreePanel>
                        </div>
                    </div>


                    <div style="margin-top: 30px"></div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
    import CSTable from "@/components/common/CSTable";
    import Pagination from "@/components/Pagination.vue";
    import CSDialog from "@/components/common/CSDialog";
    import CSRadio from "@/components/common/CSRadio";
    import CSSelect from "@/components/common/CSSelect";
    import ChooseLocation from "@/components/ChooseLocation";
    import TreePanel from "@/components/common/TreePanel";
    import {
        queryIdentityConfigUrl,
        searchLesseeByNameUrl,
        deleteVipUserUrl,
        addVipUserUrl,
        queryVipUserListUrl,
        editVipUserUrl,
        checkImageUrl,
        checkFaceImageUrl,
    } from "@/requestUrl";

    export default {
        name: "Privilege",
        components: {
            CSTable,
            Pagination,
            CSDialog,
            CSRadio,
            CSSelect,
            ChooseLocation,
            TreePanel
        },
        data() {
            return {
                isUpdate: false,
                filterHeight: 0,
                addVipUserFlag: false,
                isOpenVerify: false,
                infoCheckBox: 0,
                search: "",
                lesseeList: [],
                vipUserList: [],
                addVipUserParams: {
                    regionCode: "",
                    phone: "",
                    showVip: 0,
                    name: "",
                    matching: 0,
                    gender: 3,
                    birthday: "",
                    imageUrl: '',
                    lesseeId: "",
                    lesseeName: ""
                },
                showVipUserFlag: false,
                showVipUserInfo: {},
                openEditVipUserFlag: false,
                editVipUser: {
                    id: null,
                    showVip: 0,
                    name: "",
                    gender: 3,
                    birthday: "",
                    faceImage: "",
                    lesseeId: "",
                    matching: 0,
                    lesseeName: ""
                }
            }
        },
        created() {
            this.isOpenIdentityVerify();
            this.queryVipUserList();
            this.$vc.on(this.$route.path, "pagination_page", "event", (page) => {
                this.queryVipUserList(page);
            });
        },
        methods: {
            ccc() {
                if (this.addVipUserParams.matching) {
                    this.addVipUserParams.matching = 1;
                } else {
                    this.addVipUserParams.matching = 0;
                }
                //  this.addVipUserParams.matching = 1;
                console.log(this.addVipUserParams.matching);
            },
            setMatch() {
                if (this.editVipUser.matching) {
                    this.editVipUser.matching = 1;
                } else {
                    this.editVipUser.matching = 0;
                }
            },
            isOpenIdentityVerify() {
                this.$fly.get(queryIdentityConfigUrl, {regionCode: this.$vc.getCurrentRegion().code}).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.isOpenVerify = res.data;
                })
            },
            openAddVipUser() {
                this.isOpenIdentityVerify();
                this.addVipUserFlag = true;
            },
            closeAddVipUser(type) {
                this.infoCheckBox = 0;
                this.search = "";
                this.lesseeList = "";
                if (type == 1) {
                    this.addVipUserFlag = false;
                    this.addVipUserParams = {
                        regionCode: "",
                        phone: "",
                        showVip: 0,
                        name: "",
                        matching: 0,
                        gender: 3,
                        birthday: "",
                        imageUrl: "",
                        lesseeId: "",
                        lesseeName: ""
                    }
                } else {
                    this.openEditVipUserFlag = false;
                    this.editVipUser = {
                        id: null,
                        showVip: 0,
                        name: "",
                        gender: 3,
                        birthday: "",
                        faceImage: "",
                        lesseeId: "",
                        matching: 0,
                        lesseeName: ""
                    }
                }
            },
            checkboxChange(type) {
                console.log('添加');
                console.log(this.addVipUserParams.name);
                if (this.addVipUserParams.name && (this.addVipUserParams.name.length < 2 || this.addVipUserParams.name.length > 5)) {
                    this.$vc.toast("姓名限2~5个字");
                    return
                }
                if (type == 1) {
                    this.addVipUserParams.regionCode = this.$vc.getCurrentRegion().code;
                    this.$fly.post(addVipUserUrl, this.addVipUserParams)
                        .then(res => {
                            if (res.code != 0) {
                                return
                            }
                            this.$vc.toast("添加成功");
                            this.queryVipUserList();
                        })
                    this.closeAddVipUser(1);
                } else {
                    if (this.editVipUser.name && (this.editVipUser.name.length < 2 || this.editVipUser.name.length > 5)) {
                        this.$vc.toast("姓名限2~5个字");
                        return
                    }
                    if (!this.search) {
                        this.editVipUser.lesseeId = "",
                            this.editVipUser.lesseeName = ""
                    }
                    this.$fly.post(editVipUserUrl, this.editVipUser)
                        .then(res => {
                            if (res.code != 0) {
                                return
                            }
                            this.$vc.toast("修改成功");
                            this.queryVipUserList();
                        })
                    this.closeAddVipUser(2);
                }
            },
            changeLocation(location) {
                console.log(location);
                this.addVipUserParams.phone = location.phone
                this.infoCheckBox = 1
                this.addVipUserParams.showVip = 1
                if (!location.notFont) {
                    this.addVipUserParams.name = location.name || ''
                    this.addVipUserParams.gender = location.gender || ''
                    this.addVipUserParams.birthday = location.birthday || ''
                    this.addVipUserParams.lesseeId = location.lesseeId || ''
                    this.addVipUserParams.lesseeName = location.lesseeName || ''
                    this.addVipUserParams.imageUrl = location.facePhoto ? 'https://cosmoss-snapshot.oss-cn-shanghai.aliyuncs.com/' + location.facePhoto : ''
                    //this.isUpdate = location.isUpdate;
                    this.search = location.lesseeName || ''
                }
                console.log(111);
                this.isUpdate = location.isUpdate;
            },
            searchLessee() {
                this.$fly.get(searchLesseeByNameUrl, {
                    search: this.search
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.lesseeList = res.data;
                })
            },
            changeLessee(item, type) {
                this.search = item.companyName;
                if (type == 1) {
                    this.addVipUserParams.lesseeId = item.id;
                    this.addVipUserParams.lesseeName = item.companyName;
                } else {
                    this.editVipUser.lesseeId = item.id;
                    this.editVipUser.lesseeName = item.companyName;
                }
                this.lesseeList = [];

            },
            //查询特权 列表
            queryVipUserList(pageNo = 1, pageSize = 10) {

                this.$fly.post(queryVipUserListUrl, {
                    pageSize,
                    pageNo,
                    regionCode: this.$vc.getCurrentRegion().code
                }).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    if (pageNo == 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            pageSize,
                            currentPage: pageNo,
                        });
                    }
                    this.vipUserList = res.data.datas;
                });
            },
            //删除特权人员
            deleteVipUser(id) {
                this.$CSDialog.confirm({
                    title: "提示",
                    message: "确认删除吗?",
                    onConfirm: () => {
                        this.$fly.get(deleteVipUserUrl, {id})
                            .then((res) => {
                                if (res.code != 0) {
                                    this.$vc.toast("删除失败");
                                    return;
                                }
                                this.$vc.toast("删除成功");
                                this.$CSDialog.instance.closeDialog();
                                this.queryVipUserList();
                            });
                    },
                });
            },
            openShowVipUser(item) {
                this.showVipUserInfo = item;
                this.showVipUserFlag = true
            },
            openEditVipUser(item) {
                this.editVipUser = {
                    id: item.id,
                    showVip: item.showVip,
                    name: item.name,
                    gender: item.gender,
                    birthday: item.birthday,
                    faceImage: item.faceImage,
                    lesseeId: item.lesseeId,
                    matching: item.matching,
                    lesseeName: item.lesseeName
                }
                this.isUpdate = item.isUpdate;
                this.search = item.lesseeName
                this.infoCheckBox = (item.name || item.gender == 2 || item.gender == 1 || item.birthday || item.faceImage || item.lesseeName) ? 1 : 0;
                this.openEditVipUserFlag = true
            },


            //添加上传照片
            uploadAddEnterprisePhoto() {
                $("#uploadAddEnterprisePhotoId").trigger("click");
            },
            async showImage(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    var file = photoFiles[0];
                    if (file.size > 1024 * 1024 * 2) {
                        this.$vc.toast("图片大小不能超过 2M!");
                        return false;
                    }
                }

                let formData = new FormData(); //新建FormData对象
                formData.append("file", photoFiles[0]);
                await this.checkFaceImage(formData);
                let res = 0;
                for (let i = 0; i < 5; i++) {
                    res = await this.checkImage()
                    if (res !== 1) {
                        this.$vc.toast("图片检测中");
                    } else {
                        break;
                    }
                    this.sleep(1000);
                }
                if (res != 1) {
                    this.$vc.toast("图片检测失败");
                    return;
                }
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = async () => {
                    let imgSrc = await this.uploadPhoto(reader.result).catch(() => {
                        this.$vc.toast("上传图片错误,请重新上传");
                    });
                    if (imgSrc && imgSrc != "") {
                        this.addVipUserParams.imageUrl = imgSrc;
                    }
                    event.target.value = "";
                };
            },
            deleteFacePhoto() {
                this.addVipUserParams.imageUrl = "";
            },


            uploadAddEnterprisePhoto1() {
                $("#uploadAddEnterprisePhotoId1").trigger("click");
            },
            async showImage1(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    var file = photoFiles[0];
                    if (file.size > 1024 * 1024 * 2) {
                        this.$vc.toast("图片大小不能超过 2M!");
                        return false;
                    }
                }

                let formData = new FormData(); //新建FormData对象
                formData.append("file", photoFiles[0]);
                await this.checkFaceImage(formData);
                let res = 0;
                for (let i = 0; i < 5; i++) {
                    res = await this.checkImage()
                    if (res !== 1) {
                        this.$vc.toast("图片检测中");
                    } else {
                        break;
                    }
                    this.sleep(1000);
                }
                if (res != 1) {
                    this.$vc.toast("图片检测失败");
                    return;
                }

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = async () => {
                    let imgSrc = await this.uploadPhoto(reader.result).catch(() => {
                        this.$vc.toast("上传图片错误,请重新上传");
                    });
                    if (imgSrc && imgSrc != "") {
                        this.editVipUser.faceImage = imgSrc;
                    }
                    event.target.value = "";
                };
            },
            deleteFacePhoto1() {
                this.editVipUser.faceImage = "";
            },

            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },

            async checkFaceImage(formData) {
                this.$fly.post(checkImageUrl, formData);
            },

            async checkImage() {
                let res = await this.$fly.get(checkFaceImageUrl).then();
                return res.data;
            },
            sleep(numberMillis) {
                var now = new Date();
                var exitTime = now.getTime() + numberMillis;
                while (true) {
                    now = new Date();
                    if (now.getTime() > exitTime)
                        return true;
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .dialog-form {
        font-size: 24px;
        color: #000;

        &-item {
            &:not(:last-of-type) {
                /*margin-bottom: 23px;*/
            }

            &-label {
                width: 206px;
                height: 33px;
                text-align: right;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 33px;
            }

            & > div {
                display: inline-block;
                vertical-align: middle;

                input {
                    &::placeholder {
                        color: #999;
                    }

                    width: 140px;
                    height: 40px;
                    border-radius: 4px;
                    padding: 0 5px;
                    border: 1px solid #979797;
                    vertical-align: middle;
                    padding-left: 10px;
                }
            }
        }
    }

    .dialog-content {
        padding: 30px;

        .dialog-form {
            &-field {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 24px;

                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }

                .field-name {
                    width: 240px;
                    margin-right: 40px;
                    text-align: right;
                    flex: 0 0 240px;
                }

                input {
                    border: 1px solid #979797;
                    border-radius: 4px;
                }

                .show-rule {
                    display: flex;
                    flex-flow: row nowrap;

                    // align-items center

                    &-name {
                        width: 120px;
                        flex: 0 0 120px;
                        margin-right: 40px;
                    }
                }
            }
        }
    }

    .room-form {
        padding: 27px 30px;

        .field-item {
            margin-bottom: 22px;
            font-size: 0;

            &-label {
                font-size: 24px;
                text-align: right;
                width: 208px;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: 0;
            }

            &-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;

                input {
                    width: 400px;
                    height: 40px;
                    text-indent: 5px;
                    border-radius: 4px;
                    border: 1px solid #979797;

                    &::placeholder {
                        color: #999;
                    }
                }

                select {
                    width: 220px;
                    text-indent: 5px;
                }
            }
        }

        .tips {
            color: #999;
            font-size: 20px;
        }
    }

    .dialog-form-item {
        padding-top 30px
    }

    .dialog-form-item-label {
        width: 150px;
    }

    .dot {
        margin-top 10px
        margin-left -20px
        width: 14px;
        height: 14px;
        background: #00B694;
        border-radius: 50%;
        opacity: 1;
        position absolute;

    }

    .nameSlect {
        border none
        height 0
        width 20px !important
        height 20px !important
        margin-right 10px
    }

    .privilegeClass {
        display flex
        vertical-align top
        flex-wrap: nowrap
        margin-bottom 10px
    }

    .privilegelab {
        width 100px
        text-align right
        margin-right 40px
        flex 0 0 auto
    }

    .disabledCl::after {
        background-color #cccccc !important
    }
</style>
<style>
    .wDatad .el-icon-caret-bottom {
        padding-top: 5px !important;

    }

    .wDatad input {
        font-size: 24px;
    }

    .wDatad input::-webkit-input-placeholder {
        color: #999999;
    }
</style>